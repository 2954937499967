// switch to opsapi
import { useHistory, useParams } from "react-router";
import Loader from "../../../ui/components/Loader/Loader";
import { FlatButton } from "../_components/FlatButton";
import { SyncStateBar } from "../_components/SyncStateBar";

import { ChevronDoubleRightIcon, PencilIcon } from "@heroicons/react/24/solid";
import { BrandStateEnum } from "opsapi/hooks/operations/brands";
import { useListBrandSectionsQuery } from "opsapi/hooks/operations/brandSections";
import { useListCategoriesQuery } from "opsapi/hooks/operations/categories";
import { useListItemsQuery } from "opsapi/hooks/operations/items";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import {
  StorefrontTypeEnum,
  useListStorefrontsQuery,
  useViewStorefrontQuery,
} from "opsapi/hooks/operations/storefronts";
import { useState } from "react";
import { getResponsiveImageUrl } from "../../../utils/formatters";
import { ItemsContainer } from "../_components/ItemsContainer";

export const ViewStorefront = () => {
  const history = useHistory();
  const { locationSlug, storefrontSlug } =
    useParams<{ locationSlug: string; storefrontSlug: string }>();

  const [isAllOpen, setIsAllOpen] = useState(true);

  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  const storefrontsParams = {
    params: { query: { location_slug: locationSlug } },
  };
  const { data: storefronts, isLoading: isStorefrontsLoading } =
    useListStorefrontsQuery(storefrontsParams);

  const storefrontParams = {
    params: { path: { slug: storefrontSlug } },
  };
  const { data: currentStorefront, isLoading: isCurrentStorefrontLoading } =
    useViewStorefrontQuery(storefrontParams);

  const { data: categories, isLoading: isCategoriesLoading } =
    useListCategoriesQuery({
      reactQuery: {
        enabled: currentStorefront?.type === StorefrontTypeEnum.consolidated,
      },
    });
  const { data: brandSections, isLoading: isBrandSectionsLoading } =
    useListBrandSectionsQuery({
      reactQuery: {
        enabled: currentStorefront?.type === StorefrontTypeEnum.brand,
      },
      params: { query: { storefront_slug: storefrontSlug } },
    });

  const itemsParams = {
    params: { query: { storefront_slug: storefrontSlug } },
  };
  const { data: items, isLoading: isItemsLoading } =
    useListItemsQuery(itemsParams);

  if (
    isLocationsLoading ||
    isCurrentStorefrontLoading ||
    isStorefrontsLoading ||
    isCategoriesLoading ||
    isBrandSectionsLoading ||
    isItemsLoading
  ) {
    return <Loader loading={true} />;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  if (!storefronts) {
    return <div>No storefronts found</div>;
  }

  if (!brandSections && currentStorefront?.type === StorefrontTypeEnum.brand) {
    return <div>No brand sections found</div>;
  }

  if (
    !categories &&
    currentStorefront?.type === StorefrontTypeEnum.consolidated
  ) {
    return <div>No categories found</div>;
  }

  if (!items) {
    return <div>No items found</div>;
  }

  const currentLocation = locations.find(
    (location: { slug: string }) => location.slug === locationSlug,
  );

  if (currentLocation === undefined) {
    return <h1>Location not found</h1>;
  }
  if (currentStorefront === undefined) {
    return <h1>Storefront not found</h1>;
  }

  return (
    <div>
      <h1 className="flex gap-2 items-center justify-between text-3xl font-semibold text-gray-900 mb-2">
        <div className="flex gap-2 items-center">
          Location:
          <a
            className="underline cursor-pointer"
            href={`/storefronts/${currentLocation.slug}/`}
          >
            {currentLocation.name}
          </a>
          <ChevronDoubleRightIcon className="h-8 w-8" />
          {currentStorefront.name}
        </div>
        <div className="flex gap-2 items-center w-1/2 h-16">
          {currentStorefront.focal_brand && (
            <img
              className="h-16 w-16 border-2 border-dashed border-yellow-300"
              src={getResponsiveImageUrl(
                currentStorefront.focal_brand?.logo_url ?? undefined,
                "320w",
              )}
              title={`Focal brand: ${currentStorefront.focal_brand?.name} (${currentStorefront.focal_brand?.state})`}
            />
          )}
          {currentStorefront.brands
            .filter((brand) => brand.id != currentStorefront.focal_brand?.id)
            .map((brand) => (
              <img
                key={brand.ticker}
                className={`h-12 w-12 ${
                  brand.state === BrandStateEnum.deactivated ? "grayscale" : ""
                }`}
                src={getResponsiveImageUrl(brand.logo_url ?? undefined, "320w")}
                title={`${brand.name} (${brand.state})`}
              />
            ))}
        </div>
      </h1>

      <div className="flex w-full">
        <div className="flex flex-col">
          {storefronts?.map((storefront) => (
            <FlatButton
              key={storefront.slug}
              disabled={storefront.slug === storefrontSlug}
              className="h-[60px] w-[240px] m-2"
              onClick={() =>
                history.push(
                  `/storefronts/${currentLocation.slug}/${storefront.slug}/`,
                )
              }
            >
              <div className="flex flex-col items-center justify-center w-full">
                <span className="text-nowrap">{storefront.name}</span>
                <span className="text-xs font-waight-200">
                  {`<${storefront.type}>`}
                </span>
              </div>
            </FlatButton>
          ))}
          <FlatButton
            className="h-[60px] w-[240px] rounded-full justify-center m-2"
            onClick={() =>
              history.push(`/storefronts/${currentLocation.slug}/create/`)
            }
          >
            Create new
          </FlatButton>
        </div>
        <div className="flex flex-col w-full m-2">
          <div className="flex gap-2 content-center mb-4">
            <SyncStateBar storefront={currentStorefront} />
            <button
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold px-4 rounded"
              onClick={() =>
                history.push(
                  `/storefronts/${currentLocation.slug}/${currentStorefront.slug}/update`,
                )
              }
            >
              <PencilIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="rounded bg-gray-200 w-full p-4 text-center flex flex-col gap-2">
            <div className="flex justify-end">
              {isAllOpen ? (
                <button onClick={() => setIsAllOpen(false)}>Hide all</button>
              ) : (
                <button onClick={() => setIsAllOpen(true)}>Show all</button>
              )}
            </div>
            {currentStorefront.type === StorefrontTypeEnum.consolidated &&
              categories &&
              categories.map((category) => {
                return (
                  <ItemsContainer
                    key={`${category.id}-${isAllOpen}`}
                    items={items.filter(
                      (item) => item.category?.id === category.id,
                    )}
                    title={category.name}
                    shouldCollapse={!isAllOpen}
                  />
                );
              })}
            {currentStorefront.type === StorefrontTypeEnum.brand &&
              brandSections &&
              brandSections.map((brandSection) => {
                return (
                  <ItemsContainer
                    key={`${brandSection.id}-${isAllOpen}`}
                    items={items.filter(
                      (item) => item.brand_section?.name === brandSection.name,
                    )}
                    title={brandSection.name}
                    shouldCollapse={!isAllOpen}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import {
  EditorialModule,
  PlatformMap,
  PlatformMapTypes,
  PublicMenuItem,
} from "../../../../types";
import { Button, Table, TableData, TableRow } from "../../../../ui";
import { ConfirmationModal } from "../../../../ui/ConfirmationModal";
import Toggle from "../../../../ui/components/Toggle";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { UpdateLocationEditorialModuleModal } from "./UpdateLocationEditorialModuleModal";
import useBulkUpdateEditorialModules from "./hooks/useBulkUpdateEditorialModules";
import { useUpdateEditorialModule } from "./hooks/useUpdateEditorialModule";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface LocationEditorialModulesTableProps {
  defaultEditorialModules: EditorialModule[];
  onUpdateEditorialModule: (
    editorialModule: Partial<EditorialModule> & { editorial_module_id: number },
  ) => void;
  onDeleteEditorialModule: (editorialModule: Partial<EditorialModule>) => void;
  menu_items: PublicMenuItem[];
}

export default function LocationEditorialModulesTable({
  defaultEditorialModules,
  onUpdateEditorialModule,
  onDeleteEditorialModule,
  menu_items,
}: LocationEditorialModulesTableProps) {
  const { showToast } = useContext(ToastContext);
  const [editorialModules, setEditorialModules] = useState<EditorialModule[]>(
    defaultEditorialModules,
  );

  useEffect(() => {
    setEditorialModules(defaultEditorialModules);
  }, [defaultEditorialModules]);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [editorialModuleToDelete, setEditorialModuleToDelete] =
    useState<any>(null);
  const [editorialModuleToUpdate, setEditorialModuleToUpdate] =
    useState<any>(null);

  const clickUpdateButton = (editorialModule: any) => {
    setEditorialModuleToUpdate({
      ...editorialModule,
    });
    setShowUpdateModal(true);
  };

  const handleClick = async (editorialModule: any) => {
    setEditorialModuleToDelete(editorialModule);
    setShowConfirmDelete(true);
  };
  const editorialModuleSortFunction = useCallback(
    (tempA: EditorialModule, tempB: EditorialModule) => {
      if (tempA.is_active === tempB.is_active) {
        return tempA.ordinal < tempB.ordinal ? -1 : 1;
      } else {
        return tempA.is_active > tempB.is_active ? -1 : 1;
      }
    },
    [],
  );

  useEffect(() => {
    const tempEditorialModules: EditorialModule[] = JSON.parse(
      JSON.stringify(defaultEditorialModules),
    );
    tempEditorialModules.sort(editorialModuleSortFunction);

    setEditorialModules(tempEditorialModules);
  }, [defaultEditorialModules]);

  const onCompleted = useCallback(
    async (data: { update_editorial_module: EditorialModule }) => {
      const tempEditorialModules = editorialModules.map((editorial_module) => {
        return editorial_module.id === data.update_editorial_module.id
          ? data.update_editorial_module
          : editorial_module;
      });
      tempEditorialModules.sort(editorialModuleSortFunction);
      const changedEditorialModules: Pick<EditorialModule, "id" | "ordinal">[] =
        [];
      const newEditorialModules = tempEditorialModules.map(
        (editorial_module, index) => {
          if (editorial_module.ordinal !== index) {
            changedEditorialModules.push({
              id: parseInt(editorial_module.id.toString(), 10),
              ordinal: index,
            });
          }
          return { ...editorial_module, ordinal: index };
        },
      );

      if (changedEditorialModules.length) {
        await bulkUpdateEditorialModules({
          variables: { editorialModules: changedEditorialModules },
        });
      }
      setEditorialModules(newEditorialModules);
    },
    [editorialModules],
  );

  const toggleIsActive = useUpdateEditorialModule(onCompleted);

  const { bulkUpdateEditorialModules } = useBulkUpdateEditorialModules();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedEditorialModules = reorder(
      editorialModules,
      result.source.index,
      result.destination!.index,
    );

    const changedEditorialModules: Pick<EditorialModule, "id" | "ordinal">[] =
      [];
    const editorialModuleCallbacks: Function[] = [];

    const newEditorialModules = reorderedEditorialModules.map(
      (editorialModule, index) => {
        if (editorialModule.ordinal !== index) {
          editorialModuleCallbacks.push(() =>
            analytics.track("Editorial Module Ordinal Updated", {
              editorial_moduleId: editorialModule.id,
              previousOrdinal: editorialModule.ordinal,
              newOrdinal: index,
            }),
          );

          changedEditorialModules.push({
            id: parseInt(editorialModule.id, 10),
            ordinal: index,
          });
        }

        return { ...editorialModule, ordinal: index };
      },
    );

    if (changedEditorialModules.length) {
      bulkUpdateEditorialModules({
        variables: { editorialModules: changedEditorialModules },
      })
        .then(() => {
          editorialModuleCallbacks.forEach((callback) => callback());
        })
        .catch(() => {
          showToast({
            description: "Editorial Module order has not been updated",
            variant: "error",
            seconds: 2,
            onClose: () => {},
          });
        });

      const tempEditorialModules: EditorialModule[] = JSON.parse(
        JSON.stringify(newEditorialModules),
      );
      tempEditorialModules.sort(editorialModuleSortFunction);
      setEditorialModules(tempEditorialModules);
    }
  };

  const tableHeaders = [
    "Status",
    "Title",
    "Subtitle",
    "Platforms",
    "Item count",
    "Action",
  ];

  return (
    <div className="flex flex-col overflow-x-hidden">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="editorial-modules-droppable">
          {(droppableProvided) => (
            <Table
              headers={tableHeaders}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {editorialModules.map(function (
                editorialModule: EditorialModule,
                index: number,
              ) {
                const toggleActive = (e: MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleIsActive({
                    variables: {
                      id: parseInt(editorialModule.id.toString(), 10),
                      input: {
                        is_active: !editorialModule.is_active,
                      },
                    },
                  });
                };
                return (
                  <Draggable
                    key={editorialModule.id}
                    draggableId={"" + editorialModule.id}
                    index={index}
                  >
                    {(draggableProvided) => {
                      return (
                        <TableRow
                          rowColor={index % 2 ? "bg-white" : "bg-gray-50"}
                          onClick={() => {
                            clickUpdateButton(editorialModule);
                          }}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={draggableProvided.draggableProps.style}
                        >
                          <TableData
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Toggle
                              initialValue={editorialModule.is_active}
                              onChange={toggleActive}
                            />
                          </TableData>
                          <TableData className="w-36 lg:w-48 xl:w-72">
                            <p className="w-36 truncate text-ellipsis">
                              {editorialModule.title}
                            </p>
                          </TableData>
                          <TableData className="w-36 lg:w-48 xl:w-72">
                            <p className="w-36 truncate text-ellipsis">
                              {editorialModule.subtitle}
                            </p>
                          </TableData>
                          <TableData className="w-36 lg:w-48 xl:w-72">
                            <p className="w-36 truncate text-ellipsis">
                              {editorialModule.platforms
                                ?.map(
                                  (platform) =>
                                    PlatformMap[
                                      platform as keyof PlatformMapTypes
                                    ],
                                )
                                .join(", ") || "No Platforms"}
                            </p>
                          </TableData>
                          <TableData className="w-36 lg:w-48 xl:w-72">
                            <p className="w-36 truncate text-ellipsis">
                              {editorialModule.menu_items?.length}
                            </p>
                          </TableData>
                          <TableData>
                            <Button
                              className="flex items-center"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleClick(editorialModule);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="mr-2"
                              />
                              Remove
                            </Button>
                          </TableData>
                        </TableRow>
                      );
                    }}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      <UpdateLocationEditorialModuleModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        editorialModuleToUpdate={editorialModuleToUpdate}
        onUpdateEditorialModule={onUpdateEditorialModule}
        menu_items={menu_items}
      />

      <ConfirmationModal
        header="Confirm delete"
        message="Are you sure you want to delete this editorial module?"
        show={showConfirmDelete}
        confirmMessage="Delete"
        onConfirm={() => {
          onDeleteEditorialModule(editorialModuleToDelete);
          setEditorialModuleToDelete(null);
          setShowConfirmDelete(false);
        }}
        hasConfirm
        hasCancel
        onCancel={() => {
          setEditorialModuleToDelete(null);
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
}

import { Identifier } from "dnd-core";
import { Dispatch, SetStateAction } from "react";
import { useDrop } from "react-dnd";
import { PublicMenuItem } from "../../../../types";
import { Modal } from "../../../../ui";
import { DragItem } from "../../../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../../../ui/components/SearchAndDrag/SearchAndDrag";
import EditorialModuleForm from "./EditorialModuleForm";

type UpdateEditorialModuleModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onUpdateEditorialModule?: any;
  editorialModuleToUpdate: any;
  menu_items?: PublicMenuItem[];
};

export const UpdateLocationEditorialModuleModal = ({
  showModal,
  setShowModal,
  onUpdateEditorialModule,
  editorialModuleToUpdate,
  menu_items,
}: UpdateEditorialModuleModalProps) => {
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });
  return (
    <Modal
      title="Edit menu module"
      showModal={showModal}
      setShowModal={setShowModal}
      ref={drop}
    >
      <EditorialModuleForm
        onSubmit={onUpdateEditorialModule}
        setShowModal={setShowModal}
        editorialModule={editorialModuleToUpdate}
        menu_items={menu_items}
      />
    </Modal>
  );
};

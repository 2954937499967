import { Identifier } from "dnd-core";
import { Dispatch, SetStateAction, useContext } from "react";
import { useDrop } from "react-dnd";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  EditorialModule,
  PlatformMap,
  PlatformMapTypes,
  PublicMenuItem,
} from "../../../../types";
import { Button, FormInput, FormLabel } from "../../../../ui";
import { DragItem } from "../../../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../../../ui/components/SearchAndDrag/SearchAndDrag";
import Toggle from "../../../../ui/components/Toggle";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { StoreMenuSearchAndDrag } from "./StoreMenuSearchAndDrag";

type EditorialModuleFormProps = {
  onSubmit: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  editorialModule?: EditorialModule | null;
  menu_items?: PublicMenuItem[];
};

type MultiSelectOptionType = {
  value: any;
  label: string;
};

const EditorialModuleForm = ({
  onSubmit,
  setShowModal,
  editorialModule,
  menu_items,
}: EditorialModuleFormProps) => {
  const { control, handleSubmit } = useForm();
  const { showToast } = useContext(ToastContext);

  const onSubmitHandler = async (data: any) => {
    const transformedData = {
      editorial_module_id: editorialModule?.id,
      title: data.title,
      subtitle: data.subtitle,
      platforms: data.platforms
        ? data.platforms.map(
            (platform: MultiSelectOptionType) => platform.value,
          )
        : null,
      store_menu_item_ids: data.store_menu_item_ids || [],
      is_active: data.is_active,
    };

    try {
      await onSubmit(transformedData);
      showToast({
        description: editorialModule
          ? "Editorial module was updated"
          : "Editorial module was created",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });

      setShowModal(false);
    } catch (err) {
      showToast({
        description: editorialModule
          ? "Editorial module could not be updated."
          : "Editorial module could not be created.",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} ref={drop}>
      <div className="grid grid-cols-3 gap-6 my-5">
        <div className={"col-span-3"}>
          <FormLabel
            title="Title"
            htmlFor="title"
            information="Title of the menu module."
          />
          <Controller
            name="title"
            control={control}
            defaultValue={editorialModule ? editorialModule.title : ""}
            rules={{ required: true }}
            render={({ field }) => (
              <FormInput type="text" id="title" {...field} />
            )}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Subtitle"
            htmlFor="subtitle"
            information="Subtitle of the menu module"
          />
          <Controller
            name="subtitle"
            control={control}
            defaultValue={editorialModule ? editorialModule?.subtitle : ""}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput type="text" id="subtitle" {...field} />
            )}
          />
        </div>
        <div className={"col-span-2"}>
          <FormLabel
            title="Platforms"
            htmlFor="platforms"
            information={
              <p>
                Platforms in which the editorial module will show up (
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Landing
                </code>
                <i>(localkitchens.com)</i>,{" "}
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Guest Web
                </code>
                <i>(order.localkitchens.com)</i> and{" "}
                <code className="bg-red-50 text-red-600 rounded-md p-1">
                  Kiosk
                </code>
                ).
              </p>
            }
          />
          <Controller
            name="platforms"
            control={control}
            rules={{ required: false }}
            defaultValue={
              editorialModule?.platforms?.map((platform) => ({
                value: platform,
                label: PlatformMap[platform as keyof PlatformMapTypes],
              })) || []
            }
            render={({ field }) => {
              return (
                <Select
                  options={[
                    {
                      value: "guest_web",
                      label: "Website",
                    },
                    { value: "guest_mobile", label: "Mobile App" },
                    { value: "guest_kiosk", label: "Kiosk" },
                  ]}
                  isMulti
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  {...field}
                />
              );
            }}
          />
        </div>
        <div>
          <FormLabel
            title="Is Active"
            htmlFor="is_active"
            information={"Status of the menu module"}
          />
          <Controller
            name="is_active"
            control={control}
            defaultValue={editorialModule ? editorialModule?.is_active : false}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <Toggle
                  initialValue={field.value}
                  onChange={(event, value) => {
                    field.onChange({ target: { value: value } });
                  }}
                />
              );
            }}
          />
        </div>
        <div className="col-span-3">
          <FormLabel
            title="Menu Items"
            htmlFor="store_menu_item_ids"
            information={"Menu items to show on menu module"}
          />
          <Controller
            name="store_menu_item_ids"
            control={control}
            defaultValue={
              editorialModule?.menu_items?.map((menu_item) => menu_item.id) ??
              []
            }
            rules={{ required: false }}
            render={({ field }) => (
              <StoreMenuSearchAndDrag
                selectedValues={field.value}
                storeMenuItems={menu_items || []}
                onChange={(val: number[]) => {
                  field.onChange({ target: { value: val } });
                }}
              />
            )}
          />
        </div>
        <Button className="mr-3">Save</Button>
        <Button
          type="button"
          backgroundColor="bg-gray-500"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default EditorialModuleForm;

import { Identifier } from "dnd-core";
import { Dispatch, SetStateAction } from "react";
import { useDrop } from "react-dnd";
import { PublicMenuItem } from "../../../../types";
import { Modal } from "../../../../ui";
import { DragItem } from "../../../../ui/components/SearchAndDrag/DraggableItem";
import { ItemTypes } from "../../../../ui/components/SearchAndDrag/SearchAndDrag";
import EditorialModuleForm from "./EditorialModuleForm";

type CreateEditorialModuleModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCreateEditorialModule: any;
  menu_items: PublicMenuItem[];
};

export const CreateLocationEditorialModuleModal = ({
  showModal,
  setShowModal,
  onCreateEditorialModule,
  menu_items,
}: CreateEditorialModuleModalProps) => {
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.selectedItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });
  return (
    <Modal
      title="New menu module"
      showModal={showModal}
      setShowModal={setShowModal}
      ref={drop}
    >
      <EditorialModuleForm
        onSubmit={onCreateEditorialModule}
        setShowModal={setShowModal}
        editorialModule={null}
        menu_items={menu_items}
      />
    </Modal>
  );
};

import { useMutation, gql } from "@apollo/client";

const CREATE_EDITORIAL_MODULE = gql`
  mutation CreateEditorialModule($input: CreateEditorialModuleInput!) {
    create_editorial_module(input: $input) {
      id
      title
      subtitle
      ordinal
      platforms
      is_active
      menu_items {
        id
        name
        image_url
      }
      location {
        name
        id
        zipcode
        slug
        phone_number
      }
    }
  }
`;

export const useCreateEditorialModule = () => {
  const [createEditorialModule] = useMutation(CREATE_EDITORIAL_MODULE);

  return createEditorialModule;
};

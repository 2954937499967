import { gql, useQuery } from "@apollo/client";

const ALL_LOCATION_DETAILS_QUERY = gql`
  query ALL_LOCATION_DETAILS_QUERY($slug: String) {
    all_location_details(slug: $slug) {
      id
      name
      timezone
      stripe_location_id
      toast_location_id
      yelp_location_id
      google_place_id
      street
      state
      zipcode
      phone_number
      tax_rate
      share_link
      slug
      is_dine_in_available
      total_kiosks
      service_fee_rate
      guest_experience_phone_number
      are_drinks_self_serve
      settings {
        minimum_store_orders
        line_items_throttle
      }
    }
  }
`;

const useAllLocationDetails = (slug: string) => {
  const { data, error, loading, refetch } = useQuery(
    ALL_LOCATION_DETAILS_QUERY,
    {
      variables: {
        slug,
      },
    },
  );
  return { data, error, loading, refetch };
};

export default useAllLocationDetails;

import { useBulkUpdateMenuEntitiesMutation } from "../../../graphql/useBulkUpdateMenuEntities.query.generated";
import { BulkUpdateMenuEntitiesInput } from "../../../graphql/types";

const useBulkUpdateMenuEntities = () => {
  const [bulkUpdateMenuEntities] = useBulkUpdateMenuEntitiesMutation();

  return bulkUpdateMenuEntities;
};

export default useBulkUpdateMenuEntities;

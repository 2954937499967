import { useMutation, gql } from "@apollo/client";

const BULK_UPDATE_EDITORIAL_MODULES = gql`
  mutation BulkUpdateEditorialModules(
    $editorialModules: [EditorialModuleBulkUpdateInput]
  ) {
    bulk_update_editorial_modules(editorial_modules: $editorialModules)
  }
`;

const useBulkUpdateEditorialModules = () => {
  const [bulkUpdateEditorialModules, { error }] = useMutation(
    BULK_UPDATE_EDITORIAL_MODULES,
  );
  return { bulkUpdateEditorialModules, error };
};

export default useBulkUpdateEditorialModules;

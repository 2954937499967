import { useMutation, gql } from "@apollo/client";

const BULK_UPDATE_STORE_HOURS_MUTATION = gql`
  mutation BulkUpdateStoreHours(
    $store_ids: [Int]
    $store_hours: [GenericStoreHoursInput]
  ) {
    bulk_update_store_hours(store_ids: $store_ids, store_hours: $store_hours)
  }
`;

export const useBulkUpdateStoreHours = () => {
  const [bulkUpdateStoreHours] = useMutation(BULK_UPDATE_STORE_HOURS_MUTATION, {
    refetchQueries: ["ALL_STORES_QUERY", "StoreById"],
  });

  return { bulkUpdateStoreHours };
};

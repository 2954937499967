import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Storefront3PIntegrationStateEnum,
  StorefrontIntegrationSchema,
  useUpdateStorefrontIntegrationQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import { StorefrontSchema } from "opsapi/hooks/operations/storefronts";
import { Fragment, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormInput,
  FormLabel,
  FormOption,
  FormSelect,
} from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { IntegrationOnboardingDialog } from "./IntegrationOnboardingDialog";

interface IntegrationEditDialogProps {
  showModal: boolean;
  hideModal: () => void;
  storefront: StorefrontSchema;
  integration: StorefrontIntegrationSchema;
}

export const IntegrationEditDialog = ({
  showModal,
  hideModal,
  storefront,
  integration,
}: IntegrationEditDialogProps) => {
  const { showToast } = useContext(ToastContext);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const storefrontIntegrationsParams = {
    params: {
      path: {
        storefront_slug: storefront.slug,
        storefront_integration_name: integration.name,
      },
    },
  };

  const {
    mutate: updateStorefrontIntegration,
    isPending: isStorefrontIntegrationUpdatingPending,
  } = useUpdateStorefrontIntegrationQuery(storefrontIntegrationsParams);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      external_store_id: integration.external_store_id,
      state: integration.state,
    },
  });

  const onSubmitUpdateIntegrationHandler = async (data: any) => {
    updateStorefrontIntegration(
      { ...data, storefront_slug: storefront.slug },
      {
        onSuccess: () => {
          showToast({
            description: `Submitted successfully`,
            variant: "success",
            seconds: 2,
          });
          hideModal();
        },
        onError: (error) => {
          showToast({
            description: error.message,
            variant: "error",
            seconds: 10,
          });
        },
      },
    );
  };

  return (
    <>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={showModal}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div>
                  <div className="flex w-full flex-row justify-between">
                    <Dialog.Title
                      as="h3"
                      className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                    >
                      Storefronts {integration.name} Integration
                    </Dialog.Title>

                    <XMarkIcon
                      onClick={() => hideModal()}
                      className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col my-4 space-y-4">
                  <form
                    onSubmit={handleSubmit(onSubmitUpdateIntegrationHandler)}
                    className="relative"
                  >
                    <div className="grid grid-cols-3 gap-6 my-5">
                      <div className="col-span-2">
                        <FormLabel
                          title="External Store ID"
                          htmlFor="external_store_id"
                          information={`
                            ID of the storefront in the external system.
                            Leave the field blank if not onboarded yet.
                          `}
                        />
                        <Controller
                          name="external_store_id"
                          control={control}
                          render={({ field }) => (
                            <div className="flex items-center">
                              <FormInput
                                type="text"
                                id="external_store_id"
                                disabled={integration.is_legacy_store}
                                {...field}
                              />
                              <a
                                // There is a known issue with Yalla stores:
                                // they were created using onboarding flow in integrations service and have external ids
                                // matching DB records in DB. So that we have no column in the new schema to put actual DD store ids.
                                href={`https://merchant-portal.doordash.com/merchant/store-availability?store_id=${field.value}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ArrowTopRightOnSquareIcon className="m-1 w-8 h-8" />
                              </a>
                            </div>
                          )}
                        />
                        <p className="text-red-800">
                          {errors.external_store_id?.message?.toString()}
                        </p>
                        <button
                          className="text-blue-500 underline underline-offset-1"
                          onClick={(e) => {
                            hideModal();
                            setShowOnboardingModal(true);
                            e.preventDefault(); // don't submit update form
                          }}
                        >
                          Start Onboarding if store is not created yet on 3P
                          side
                        </button>
                      </div>
                      <div className="col-span-2">
                        <FormLabel
                          title="External Menu ID"
                          htmlFor="external_menu_id"
                          information="External Menu Id, we receive it after menu is pushed to 3P for the first time via webhook."
                        />
                        <FormInput
                          type="text"
                          id="external_menu_id"
                          value={integration.external_menu_id}
                          disabled={true}
                        />
                      </div>
                      <div className="col-span-2">
                        <FormLabel
                          title="State"
                          htmlFor="state"
                          information="Activate/deactivate storefront for guests"
                        />
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormSelect {...field}>
                              {Object.values(
                                Storefront3PIntegrationStateEnum,
                              ).map((state) => (
                                // Only enable consolidated storefronts
                                <FormOption
                                  key={state}
                                  title={state}
                                  className="capitalize"
                                  value={state}
                                />
                              ))}
                            </FormSelect>
                          )}
                        />
                        <p className="text-red-800">
                          {errors.state?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-2">
                        <FormLabel
                          title="Last Sync State"
                          htmlFor="sync_state"
                          information="Last sync state of this storefront integration"
                        />
                        <p>
                          {integration.last_sync_at ?? "Never synced"}:{" "}
                          {integration.last_sync_state}
                        </p>
                        <p>
                          Details:{" "}
                          {JSON.stringify(
                            integration.last_sync_state_details || "-",
                          )}
                        </p>
                        <p>
                          Last succesfull sync date:{" "}
                          {integration.last_successful_sync_at || "-"}
                        </p>
                      </div>
                    </div>
                    <Button
                      className="mr-3"
                      loading={isStorefrontIntegrationUpdatingPending}
                    >
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <IntegrationOnboardingDialog
        showModal={showOnboardingModal}
        hideModal={() => setShowOnboardingModal(false)}
        storefront={storefront}
      />
    </>
  );
};

import { useMutation, gql } from "@apollo/client";

const DELETE_EDITORIAL_MODULE = gql`
  mutation DeleteEditorialModule($id: Int!) {
    delete_editorial_module(id: $id)
  }
`;

export const useDeleteEditorialModule = () => {
  const [deleteEditorialModule] = useMutation(DELETE_EDITORIAL_MODULE);

  return deleteEditorialModule;
};

import { useMutation, gql } from "@apollo/client";

const UPDATE_EDITORIAL_MODULE = gql`
  mutation UpdateEditorialModule(
    $id: Int!
    $input: UpdateEditorialModuleInput
  ) {
    update_editorial_module(id: $id, input: $input) {
      id
      title
      subtitle
      ordinal
      is_active
      platforms
      menu_items {
        id
        name
        image_url
      }
      location {
        name
        id
        zipcode
        slug
        phone_number
      }
    }
  }
`;

export const useUpdateEditorialModule = (onCompleted?: (data: any) => void) => {
  const [updateEditorialModule] = useMutation(UPDATE_EDITORIAL_MODULE, {
    onCompleted,
  });

  return updateEditorialModule;
};

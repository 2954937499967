import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@localkitchens/passwordless-auth";
import { usePullFromToast } from "opsapi/hooks/operations/toast";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Modal } from "../../ui";
import { Loading } from "../../ui/components/Loading";
import ToastContext from "../../utils/contexts/ToastContext";
import useStoreById from "./hooks/useStoreById";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  storeId: number;
}

enum DisplayOptions {
  NEW_ITEMS_DO_DISPLAY = "NEW_ITEMS_DO_DISPLAY",
  NEW_ITEMS_DO_NOT_DISPLAY = "NEW_ITEMS_DO_NOT_DISPLAY",
  ALL_ITEMS_DO_DISPLAY = "ALL_ITEMS_DO_DISPLAY",
  ALL_ITEMS_DO_NOT_DISPLAY = "ALL_ITEMS_DO_NOT_DISPLAY",
}

const getSyncDisplayValues = (
  displayOption: DisplayOptions,
): {
  existing_item_display_to_guests?: boolean;
  new_item_display_to_guests: boolean;
} => {
  let new_item_display_to_guests = undefined;
  let existing_item_display_to_guests = undefined;

  switch (displayOption) {
    case DisplayOptions.NEW_ITEMS_DO_DISPLAY:
      new_item_display_to_guests = true;
      break;
    case DisplayOptions.NEW_ITEMS_DO_NOT_DISPLAY:
      new_item_display_to_guests = false;
      break;
    case DisplayOptions.ALL_ITEMS_DO_DISPLAY:
      existing_item_display_to_guests = true;
      new_item_display_to_guests = true;
      break;
    case DisplayOptions.ALL_ITEMS_DO_NOT_DISPLAY:
    default:
      existing_item_display_to_guests = false;
      new_item_display_to_guests = false;
  }

  return {
    existing_item_display_to_guests,
    new_item_display_to_guests,
  };
};

export const StoreMenuSyncModal = ({
  showModal,
  setShowModal,
  storeId,
}: Props) => {
  const { data: storeData, loading: storeLoading } = useStoreById({
    store_id: storeId,
  });
  const [pushToUberEats, setPushToUberEats] = useState(false);
  const [pushToDoorDash, setPushToDoorDash] = useState(false);
  const [pullFromToast, setPullFromToast] = useState(true);
  const [displayOption, setDisplayOption] = useState<DisplayOptions>(
    DisplayOptions.NEW_ITEMS_DO_DISPLAY,
  );

  const { showToast } = useContext(ToastContext);
  const {
    mutate: triggerPullFromToast,
    isPending: isTriggerPullFromToastPending,
  } = usePullFromToast();

  const { user } = useAuth();

  const handleSetDisplayOption = (value: any) => {
    switch (value) {
      case DisplayOptions.NEW_ITEMS_DO_DISPLAY:
      case DisplayOptions.NEW_ITEMS_DO_NOT_DISPLAY:
      case DisplayOptions.ALL_ITEMS_DO_DISPLAY:
      case DisplayOptions.ALL_ITEMS_DO_NOT_DISPLAY:
        setDisplayOption(value);
        break;
    }
  };

  if (storeLoading) {
    return <Loading />;
  }
  const store = storeData.store_by_id;

  const onSubmitHandler = async (e: any) => {
    const token = user?.sub;
    try {
      const { new_item_display_to_guests, existing_item_display_to_guests } =
        getSyncDisplayValues(displayOption);

      await triggerPullFromToast({
        store_id: storeData?.store_by_id.id,
        pull_from_toast: pullFromToast,
        push_to_ubereats: pushToUberEats,
        new_item_display_to_guests,
        existing_item_display_to_guests:
          existing_item_display_to_guests ?? false,
      });
      setShowModal(false);
      showToast({
        description: "Menu Synced",
        variant: "success",
        seconds: 3,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "There was an error Syncing the Menu",
        variant: "error",
        seconds: 3,
        onClose: () => {},
      });
    }
  };

  return (
    <Modal
      title="Confirmation"
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="my-5">
        <p>Would you also like to pull the latest menu from Toast?</p>
        <div className="flex items-center my-3">
          <div className="flex items-center h-5">
            <input
              id="toast-pull"
              aria-describedby="toast-pull-description"
              name="toast-pull"
              type="checkbox"
              defaultChecked={pullFromToast}
              onChange={(e) => setPullFromToast(e.target.checked)}
              className="focus:ring-indigo-500 h-4 w-4 text-lfg-primary border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="toast-pull" className="font-medium text-grary-700">
              Pull from Toast
            </label>
          </div>
        </div>
        <p>
          Would you also like to push menu updates to Ubereats and Doordash?
        </p>
        <div className="flex items-center my-3">
          <div className="flex items-center h-5">
            <input
              id="ubereats-push"
              aria-describedby="ubereats-push-description"
              name="ubereats-push"
              type="checkbox"
              disabled={!store?.ubereats_store_id}
              onChange={(e) => setPushToUberEats(e.target.checked)}
              className="focus:ring-indigo-500 h-4 w-4 text-lfg-primary border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="ubereats-push"
              className={`font-medium ${
                store?.ubereats_store_id ? "text-gray-700" : "text-gray-500"
              }`}
            >
              Push to Uber Eats
            </label>
            {!store?.ubereats_store_id && (
              <span className="text-gray-500 ml-2">
                You cannot push to Ubereats without setting the Ubereats Store
                Id.
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center h-5">
            <input
              id="doordash-push"
              aria-describedby="doordash-push-description"
              name="doordash-push"
              type="checkbox"
              onChange={(e) => setPushToDoorDash(e.target.checked)}
              disabled={true}
              className="focus:ring-indigo-500 h-4 w-4 text-lfg-primary border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="doordash-push"
              className={`font-medium ${
                store?.doordash_store_id ? "text-gray-700" : "text-gray-500"
              }`}
            >
              Push to DoorDash (will be triggered by the system anyway, ask Eng
              team if you need to push manually)
            </label>
          </div>
        </div>
        <p className={"my-3"}>Set display options on sync:</p>
        <div className="flex items-center my-4">
          <div className="flex items-center h-5">
            <select
              value={displayOption}
              onChange={(e) => handleSetDisplayOption(e.target.value)}
            >
              <option value={DisplayOptions.NEW_ITEMS_DO_DISPLAY}>
                Display new menu & mod items
              </option>
              <option value={DisplayOptions.NEW_ITEMS_DO_NOT_DISPLAY}>
                Hide new menu & mod items
              </option>
              <option value={DisplayOptions.ALL_ITEMS_DO_DISPLAY}>
                Display all menu & mod items
              </option>
              <option value={DisplayOptions.ALL_ITEMS_DO_NOT_DISPLAY}>
                Hide all menu & mod items
              </option>
            </select>
          </div>
        </div>
      </div>
      <Button
        onClick={onSubmitHandler}
        type="button"
        className="items-center"
        loading={isTriggerPullFromToastPending}
      >
        <FontAwesomeIcon icon={faSync} className="mr-2" />
        Sync
      </Button>
    </Modal>
  );
};

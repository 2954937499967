import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "@localkitchens/passwordless-auth";
import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import {
  OnboardDoordashStoreResponse,
  useOnboardDoordashStoreQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import { StorefrontSchema } from "opsapi/hooks/operations/storefronts";
import { Fragment, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button, FormInput, FormLabel } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";

interface IntegrationOnboardingDialogProps {
  showModal: boolean;
  hideModal: () => void;
  storefront: StorefrontSchema;
}

export const IntegrationOnboardingDialog = ({
  showModal,
  hideModal,
  storefront,
}: IntegrationOnboardingDialogProps) => {
  const { showToast } = useContext(ToastContext);
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  const { user } = useAuth();
  const [submitResponse, setSubmitResponse] = useState<
    OnboardDoordashStoreResponse | undefined
  >(undefined);

  const {
    mutate: onboardDoordashStore,
    isPending: isOnboardDoordashStorePending,
  } = useOnboardDoordashStoreQuery({});

  const currentLocation = locations?.find(
    (location: { slug: string }) => location.slug === locationSlug,
  );

  const {
    control: onboardingControl,
    handleSubmit,
    formState: { errors: onboardingErrors },
    getValues,
  } = useForm({
    defaultValues: {
      partner_store_id: storefront.slug,
      partner_business_id: undefined,
      doordash_store_id: undefined,
      doordash_business_id: undefined,
      partner_store_name: storefront.name,
      partner_location_name: `${storefront.name}`,
      provider_type: undefined, // set on a backend
      address_line_1: currentLocation?.street,
      address_line_2: undefined,
      address_city: currentLocation?.name,
      address_state: currentLocation?.state,
      address_zip: currentLocation?.zipcode,
      requestor_first_name: user?.name.split(" ")[0],
      requestor_last_name: user?.name.split(" ")[1],
      requestor_email: user?.email,
      requestor_phone: (user as any)?.phone_number, // it exists! ignore TS error
      expected_go_live_date: undefined,
      merchant_decision_maker_email: "",
    },
  });

  if (isLocationsLoading) {
    return <div>Loading...</div>;
  }

  if (!locations) {
    return <div>No locations found</div>;
  }

  if (!currentLocation) {
    return <div>No location found</div>;
  }

  const onboardingHandleSubmit = async (data: any) => {
    console.log(data);
    onboardDoordashStore(data, {
      onSuccess: (data) => {
        setSubmitResponse(data);
        showToast({
          description: `Submitted successfully`,
          variant: "success",
          seconds: 2,
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    });
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={showModal}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex w-full flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="flex items-start justify-between text-2xl leading-6 font-medium text-gray-900"
                  >
                    Doordash Store Onboarding
                  </Dialog.Title>

                  <XMarkIcon
                    onClick={hideModal}
                    className="cursor-pointer h-8 w-8 -mt-2 -mr-2"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {submitResponse && JSON.stringify(submitResponse, null, 2)}
              {!submitResponse && (
                <div className="flex flex-col my-4 space-y-4">
                  <form
                    onSubmit={handleSubmit(onboardingHandleSubmit)}
                    className="relative"
                  >
                    <div className="grid grid-cols-6 gap-4 my-5">
                      <div className="col-span-7 whitespace-pre-line">
                        <p>
                          Onboard a store to Doordash. See{" "}
                          <a
                            className="text-blue underline"
                            href="https://developer.doordash.com/en-US/docs/marketplace/overview/onboarding/sow/#request-fields-details"
                          >
                            docs
                          </a>
                          .
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Readonly] LK Store Id"
                          htmlFor="partner_store_id"
                          information={
                            <>
                              <h2>Partner Store Id</h2>
                              <p>
                                This is used to back reference our storefront in
                                doordash responses.
                              </p>
                              <p>It's set to the slug of the storefront.</p>
                            </>
                          }
                        />
                        <Controller
                          name="partner_store_id"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="partner_store_id"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.partner_store_id?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Readonly] LK Business Id"
                          htmlFor="partner_business_id"
                          information={
                            <>
                              <p>
                                Unique identifier of the parent business of the
                                store in the provider's system.
                              </p>
                              <p>
                                We have a single bussiness so we should not use
                                it.
                              </p>
                            </>
                          }
                        />
                        <Controller
                          name="partner_business_id"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="partner_business_id"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.partner_business_id?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Optional] Doordash Store Id"
                          htmlFor="doordash_store_id"
                          information={`Unique identifier of the store in DoorDash's system`}
                        />
                        <Controller
                          name="doordash_store_id"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="number"
                              id="doordash_store_id"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.doordash_store_id?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Optional] Doordash Business Id"
                          htmlFor="doordash_business_id"
                          information={`Unique identifier of the parent business of the store in DoorDash's system`}
                        />
                        <Controller
                          name="doordash_business_id"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="number"
                              id="doordash_business_id"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.doordash_store_id?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="LK Store Name"
                          htmlFor="partner_store_name"
                          information={
                            <>
                              <h2>Partner Store Name</h2>
                              <p>
                                Customer-facing name of the store (e.g., “Burger
                                Shack”).
                              </p>
                              <p>
                                If you change it, it might get overriden later
                                by storefront name which is set in our system.
                              </p>
                            </>
                          }
                        />
                        <Controller
                          name="partner_store_name"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="partner_store_name"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Optional] LK Location Name"
                          htmlFor="partner_location_name"
                          information={`Location-specific context for the store (e.g., “Burger Shack - Las Vegas”)`}
                        />
                        <Controller
                          name="partner_location_name"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="partner_location_name"
                              {...field}
                              disabled={true}
                            />
                          )}
                        />
                      </div>

                      <div className="col-span-6">
                        <FormLabel
                          title="Address line 1"
                          htmlFor="address_line_1"
                          information={`Address line 1 of the store`}
                        />
                        <Controller
                          name="address_line_1"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="address_line_1"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.address_line_1?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-6">
                        <FormLabel
                          title="[Optional] Address line 2"
                          htmlFor="address_line_2"
                          information={`Address line 2 of the store`}
                        />
                        <Controller
                          name="address_line_2"
                          control={onboardingControl}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="address_line_2"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.address_line_2?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-2">
                        <FormLabel
                          title="City"
                          htmlFor="address_city"
                          information={`City of the store's address`}
                        />
                        <Controller
                          name="address_city"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="address_city"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.address_city?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-2">
                        <FormLabel
                          title="State"
                          htmlFor="address_state"
                          information={`State of the store's address`}
                        />
                        <Controller
                          name="address_state"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="address_state"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.address_state?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-2">
                        <FormLabel
                          title="Zip code"
                          htmlFor="address_zip"
                          information={`Zip code of the store's address`}
                        />
                        <Controller
                          name="address_zip"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="address_zip"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.address_zip?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="Requester First Name"
                          htmlFor="requestor_first_name"
                          information={`First name of the user on the provider's side initiating the request`}
                        />
                        <Controller
                          name="requestor_first_name"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="requestor_first_name"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.requestor_first_name?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="Requester Last Name"
                          htmlFor="requestor_last_name"
                          information={`Last name of user on the provider's side initiating the request`}
                        />
                        <Controller
                          name="requestor_last_name"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="requestor_last_name"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.requestor_last_name?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="Requestor Email"
                          htmlFor="requestor_email"
                          information={
                            <>
                              <p>
                                Email contact for user on the provider's side
                                initiating the request.
                              </p>
                              <p>
                                This email is for receiving status updates on
                                the onboarding request
                              </p>
                            </>
                          }
                        />
                        <Controller
                          name="requestor_email"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="requestor_email"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.requestor_email?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-3">
                        <FormLabel
                          title="[Optional] Requestor Phone"
                          htmlFor="requestor_phone"
                          information={`Phone number for user on the provider's side initiating the request`}
                        />
                        <Controller
                          name="requestor_phone"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="requestor_phone"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.requestor_phone?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-5">
                        <FormLabel
                          title="[Optional] Expected Go Live Date"
                          htmlFor="expected_go_live_date"
                          information={
                            <>
                              <p>
                                Date the store intends to launch on Doordash,
                                used as a reference point for the DoorDash
                                Onboarding team.
                              </p>
                              <p>
                                DoorDash will not activate the store
                                automatically using this Date.
                              </p>
                            </>
                          }
                        />
                        <Controller
                          name="expected_go_live_date"
                          control={onboardingControl}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="expected_go_live_date"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.expected_go_live_date?.message?.toString()}
                        </p>
                      </div>

                      <div className="col-span-5">
                        <FormLabel
                          title="LK decision maker email"
                          htmlFor="merchant_decision_maker_email"
                          information={
                            <>
                              <h2>Merchant Decision Maker Email</h2>
                              <p>
                                Email address of the merchant being onboarded.
                              </p>
                              <p>
                                This email address will receive the merchant
                                consent email for them to authenticate the
                                onboarding request.
                              </p>
                              <p>
                                This user must be a Business Admin user in
                                DoorDash Merchant Portal to authenticate the
                                request.
                              </p>
                            </>
                          }
                        />
                        <Controller
                          name="merchant_decision_maker_email"
                          control={onboardingControl}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormInput
                              type="text"
                              id="merchant_decision_maker_email"
                              {...field}
                            />
                          )}
                        />
                        <p className="text-red-800">
                          {onboardingErrors.merchant_decision_maker_email?.message?.toString()}
                        </p>
                      </div>
                    </div>
                    <Button
                      className="mr-3"
                      loading={isOnboardDoordashStorePending}
                    >
                      Sumbit
                    </Button>
                  </form>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import { useListLocationsQuery } from "opsapi/hooks/operations/locations";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { FormInput } from "../../ui";
import { Button } from "../../ui/components/Button/Button";
import { PageContent } from "../../ui/components/PageContent/PageContent";
import ToastContext from "../../utils/contexts/ToastContext";
import dayjs from "../../utils/dayjs";
import useComponentIsMounted from "../../utils/hooks/useComponentIsMounted";
import useCurrentLocation from "../../utils/hooks/useCurrentLocation";
import { useOperationsBrands } from "../KitchenNames/hooks/useOperationsBrands";
import useCreateStore from "./hooks/useCreateStore";
import StoreDetailForm from "./StoreDetailForm";
import { StoreHoursForm } from "./StoreHoursForm";

export default () => {
  const { createStore } = useCreateStore();
  const form = useForm();
  const { control, handleSubmit } = form;
  const { locationSlug } = useParams<{ locationSlug: string }>();
  const history = useHistory();

  const componentIsMounted = useComponentIsMounted();

  const { data: brandsData, loading: brandsLoading } = useOperationsBrands();
  const { data: locations, isLoading: locationsLoading } =
    useListLocationsQuery();

  const { showToast } = useContext(ToastContext);

  const currentLocation = useCurrentLocation();

  const submitFormHandler = async (data: any) => {
    try {
      let transformedHours = data.store_hours.map(
        (store_hour_instance: any) => {
          return {
            id: store_hour_instance.id || null,
            day_of_week: parseInt(store_hour_instance.day_of_week),
            hours: store_hour_instance.hours
              .filter((hours: any) => !!hours.start_time)
              .map((hours: any) => {
                if (!hours.start_time || !hours.end_time) {
                  return null;
                }
                // If the end time is technically before the start time, that means the end time is the next day. That is what the end_day line is checking
                return {
                  start_day: 0,
                  end_day:
                    parseInt(hours.end_time.split(":")[0]) <
                    parseInt(hours.start_time.split(":")[0])
                      ? 1
                      : 0,
                  start_time: hours.start_time,
                  end_time: hours.end_time,
                };
              }),
          };
        },
      );

      const transformedRequestData = {
        ...data,
        start_timestamp: (dayjs(data.start_timestamp).unix() * 1000).toString(),
        store_hours: transformedHours,
      };

      const result = await createStore({
        variables: transformedRequestData,
      });
      showToast({
        description: "Store has been added",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      history.push(`/stores/${result.data.create_store}`);
    } catch (err) {
      showToast({
        description: "Store could not be added",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  if (!currentLocation || brandsLoading || !componentIsMounted) {
    return null;
  }
  return (
    <PageContent>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between my-5">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-gray-900 mr-3">
            Adding Brand to {currentLocation.name}
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <form onSubmit={handleSubmit(submitFormHandler)}>
          <Controller
            name="location_id"
            defaultValue={currentLocation.id}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="hidden" {...field} />}
          />
          <StoreDetailForm form={form} />
          <StoreHoursForm form={form} allowToCloneStoreHours={true} />
          <div className="flex items-end">
            <Button className="mr-3">Save</Button>
          </div>
        </form>
      </div>
    </PageContent>
  );
};

import MenuEditor from "./MenuEditor";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { reducer } from "./lib/reducer";
import { useToast } from "../../utils/hooks/useToast";
import useBulkUpdateMenuEntities from "./hooks/useBulkUpdateMenuEntities";
import useBulkUpdateMenuEntitiesStatus from "./hooks/useBulkUpdateMenuEntitiesStatus";
import { getFormattedUTCDate } from "../../utils/dateUtils";
import { UpdateCellInput } from "./lib/types";
import ToastContext from "../../utils/contexts/ToastContext";

const MenuEditorController = () => {
  const [jobMap, dispatch] = useReducer(reducer, {});
  const [updateSubmittedTimestamp, setUpdateSubmittedTimestamp] = useState<
    string | undefined
  >();

  const {
    status: bulkUpdateMenuEntitiesStatus,
    startPolling,
    stopPolling,
  } = useBulkUpdateMenuEntitiesStatus(updateSubmittedTimestamp || "");

  const { showToast } = useContext(ToastContext);
  const bulkUpdateMenuEntities = useBulkUpdateMenuEntities();

  useEffect(() => {
    if (updateSubmittedTimestamp) {
      startPolling(500);

      setTimeout(() => {
        setUpdateSubmittedTimestamp(undefined);
        stopPolling();
      }, 50000);
    } else {
      stopPolling();
    }
  }, [updateSubmittedTimestamp, showToast]);

  useEffect(() => {
    if (
      bulkUpdateMenuEntitiesStatus !== undefined &&
      typeof bulkUpdateMenuEntitiesStatus == "boolean" &&
      updateSubmittedTimestamp
    ) {
      if (bulkUpdateMenuEntitiesStatus) {
        stopPolling();
        showToast({
          description: "Menu entities updated successfully",
          variant: "success",
          seconds: 3,
          onClose: () => {},
        });
        setUpdateSubmittedTimestamp(undefined);
        dispatch({
          type: "remove_all",
        });
      }
    } else if (
      bulkUpdateMenuEntitiesStatus === false &&
      updateSubmittedTimestamp === undefined
    ) {
      showToast({
        description: "Menu entities updated successfully",
        variant: "success",
        seconds: 3,
        onClose: () => {},
      });
    }
  }, [
    bulkUpdateMenuEntitiesStatus,
    updateSubmittedTimestamp,
    setUpdateSubmittedTimestamp,
  ]);

  const doSubmitBulkUpdate = async () => {
    setUpdateSubmittedTimestamp(getFormattedUTCDate(1000));

    await bulkUpdateMenuEntities({
      variables: {
        input: {
          menu_entity_updates: Object.values(jobMap),
        },
      },
    });
  };

  const handleUpdateJob = (input: UpdateCellInput) => {
    const {
      displayStatus,
      targetId,
      targetType,
      defaultState,
      locationId,
      locationSlug,
    } = input;

    if (defaultState == "inactive") return;
    const initVisibility = defaultState == "visible";
    if (displayStatus !== initVisibility) {
      dispatch({
        type: "add_job",
        payload: {
          rowId: `${targetType}_${targetId}_${locationSlug}`,
          value: {
            target_type: targetType,
            target_id: targetId,
            location_id: locationId,
            display_to_guests: displayStatus,
          },
        },
      });
    } else {
      dispatch({
        type: "remove_job",
        payload: {
          rowId: `${targetType}_${targetId}_${locationSlug}`,
        },
      });
    }
  };

  const modifiedCells = useMemo(() => {
    const modifiedCells: { [key: string]: boolean } = {};
    for (let key in jobMap) {
      modifiedCells[key] = jobMap[key]?.display_to_guests || false;
    }
    return modifiedCells;
  }, [jobMap]);

  const submitStatus =
    bulkUpdateMenuEntitiesStatus && !!updateSubmittedTimestamp;

  return (
    <MenuEditor
      onSubmit={doSubmitBulkUpdate}
      onUpdateCell={handleUpdateJob}
      submitStatus={submitStatus}
      modifiedCells={modifiedCells}
    />
  );
};

export default MenuEditorController;

import { gql, useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";
import { EditorialModule, PublicMenuItem } from "../../../../types";
import { Button } from "../../../../ui";
import LocationEditorialModulesTable from "./LocationEditorialModulesTable";
import { useDeleteEditorialModule } from "./hooks/useDeleteEditorialModule";
import { useUpdateEditorialModule } from "./hooks/useUpdateEditorialModule";

export const EDITORIAL_MODULE_QUERY = gql`
  query OperationsEditorialModuleQuery($location_id: Int!) {
    get_editorial_modules(location_id: $location_id) {
      id
      title
      subtitle
      is_active
      platforms
      menu_items {
        id
        name
        image_url
      }
    }
  }
`;

export const LocationEditorialModule = ({
  location_id,
  menu_items,
  setShowModal,
}: {
  location_id: number | undefined;
  menu_items: PublicMenuItem[];
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const updateEditorialModuleMutation = useUpdateEditorialModule();
  const deleteEditorialModuleMutation = useDeleteEditorialModule();
  const { data } = useQuery<{
    get_editorial_modules: EditorialModule[];
  }>(EDITORIAL_MODULE_QUERY, { variables: { location_id } });

  const onUpdateEditorialModule = async (data: any) => {
    const { editorial_module_id, ...editorialModuleUpdateData } = data;

    await updateEditorialModuleMutation({
      variables: {
        id: parseInt(editorial_module_id, 10),
        input: editorialModuleUpdateData,
      },
      refetchQueries: [
        { query: EDITORIAL_MODULE_QUERY, variables: { location_id } },
      ],
    });
  };

  const onDeleteEditorialModule = async (data: any) => {
    const { id }: { id: string } = data;

    await deleteEditorialModuleMutation({
      variables: {
        id: parseInt(id, 10),
      },
      refetchQueries: [
        {
          query: EDITORIAL_MODULE_QUERY,
          variables: { location_id },
        },
      ],
    });
  };

  return (
    <div className="flex flex-col h-full items-center gap-2">
      <LocationEditorialModulesTable
        defaultEditorialModules={data?.get_editorial_modules || []}
        onUpdateEditorialModule={onUpdateEditorialModule}
        onDeleteEditorialModule={onDeleteEditorialModule}
        menu_items={menu_items}
      />
      <Button
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className="w-8">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        Create menu module
      </Button>
    </div>
  );
};

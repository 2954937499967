import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui";
import ToastContext from "../../../utils/contexts/ToastContext";
import { StoreHoursForm } from "../StoreHoursForm";
import { useBulkUpdateStoreHours } from "./hooks/useBulkUpdateLocationHours";
import { LocationHoursConfirmationModal } from "./LocationHoursConfirmationModal";

interface Props {
  stores: any;
}

export const LocationHours = ({ stores }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { bulkUpdateStoreHours } = useBulkUpdateStoreHours();
  const { showToast } = useContext(ToastContext);

  const form = useForm();
  const { handleSubmit } = form;

  const onSubmitHandler = async (data: any) => {
    // Create id mapping
    const storeIDs = stores.map((item: any) => item.id);
    try {
      await bulkUpdateStoreHours({
        variables: {
          store_ids: storeIDs,
          store_hours: data.store_hours.map((item: any) => ({
            hours: item.hours.map((item: any) => ({
              start_day: 0,
              end_day:
                parseInt(item.end_time.split(":")[0]) <
                parseInt(item.start_time.split(":")[0])
                  ? 1
                  : 0,
              start_time: item.start_time,
              end_time: item.end_time,
            })),
            day_of_week: parseInt(item.day_of_week),
          })),
        },
      });
      showToast({
        description: "Store Hours have been updated",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
      setShowModal(false);
    } catch (err) {
      showToast({
        description: "Store Hours could not be updated",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  return (
    <>
      <StoreHoursForm form={form} />
      <Button onClick={() => setShowModal(true)}>Update</Button>
      <LocationHoursConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmitHandler={handleSubmit(onSubmitHandler)}
      />
    </>
  );
};

import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateMenuEntitiesMutationVariables = Types.Exact<{
  input: Types.BulkUpdateMenuEntitiesInput;
}>;


export type BulkUpdateMenuEntitiesMutation = { __typename?: 'Mutation', bulk_update_menu_entities?: number | undefined };


export const BulkUpdateMenuEntitiesDocument = gql`
    mutation BulkUpdateMenuEntities($input: BulkUpdateMenuEntitiesInput!) {
  bulk_update_menu_entities(input: $input)
}
    `;
export type BulkUpdateMenuEntitiesMutationFn = Apollo.MutationFunction<BulkUpdateMenuEntitiesMutation, BulkUpdateMenuEntitiesMutationVariables>;

/**
 * __useBulkUpdateMenuEntitiesMutation__
 *
 * To run a mutation, you first call `useBulkUpdateMenuEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateMenuEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateMenuEntitiesMutation, { data, loading, error }] = useBulkUpdateMenuEntitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateMenuEntitiesMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateMenuEntitiesMutation, BulkUpdateMenuEntitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateMenuEntitiesMutation, BulkUpdateMenuEntitiesMutationVariables>(BulkUpdateMenuEntitiesDocument, options);
      }
export type BulkUpdateMenuEntitiesMutationHookResult = ReturnType<typeof useBulkUpdateMenuEntitiesMutation>;
export type BulkUpdateMenuEntitiesMutationResult = Apollo.MutationResult<BulkUpdateMenuEntitiesMutation>;
export type BulkUpdateMenuEntitiesMutationOptions = Apollo.BaseMutationOptions<BulkUpdateMenuEntitiesMutation, BulkUpdateMenuEntitiesMutationVariables>;